.row-dropdown-value,
.filter-dropdown-value {
    min-width: 5rem;
    background: transparent;
    color: #000;
    height: 3rem;
    border-radius: 10px;
    border: none !important;
}

.row-dropdown-value:focus,
.filter-dropdown-value:focus {
    border: none !important;
    box-shadow: none !important;
}

.show .row-dropdown-value:focus,
.show .filter-dropdown-value:focus {
    border: none !important;
    box-shadow: none !important;
}

.row-dropdown-menu {
    min-width: 5rem;
    padding: 10px;
    border-radius: 20px;
}

.row-dropdown-menu .dropdown-item,
.filter-dropdown-menu .dropdown-item {
    border-radius: 10px;
}

.filter-dropdown-menu.patient {
    min-width: 12rem;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
}

.filter-dropdown-value,
.row-dropdown-value {
    background-color: transparent !important;
    color: #000 !important;
}

.filter-dropdown-menu div {
    user-select: none;
    text-align: left;
}

.filter-dropdown-value.dropdown-toggle::after {
    border-top: none;
}

.add-patient-button {
    cursor: pointer;
}

.flt{
    display: flex;
    flex-direction: row;
}

.imgFilter{
    height: 25px;
    display: flex;
    margin-top: 0.32rem;
    cursor: pointer;
}

.funFilter{
    margin-right: 3.8rem;
    position: relative;
}

.patientFilter{
    padding: 20px;
    background-color: white;
    cursor: pointer;
    position: absolute;
    top: 40px;
    left: -180px;
    border: 1px solid rgb(182, 178, 178);
    border-radius: 20px;
}

.selections{
    margin-top: -1rem;
    margin-bottom: 1.3rem;
    position: relative;
}

.tooltiptext {
    visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 17%;
  /* margin-left: -60px; */
}

.selections:hover .tooltiptext {
    visibility: visible;
  }

.formS{
    border-radius: 20px;
}

.nameFacility{
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

.nameF:nth-child(2){
    display: flex;
    margin-left: 2.2rem;
}

.nameF:nth-child(3){
    display: flex;
    margin-left: 2.2rem;
}

.inputF{
    margin-bottom: -1rem;
}

.apply{
    text-align: center;
}

.selections.form-floating>.form-control, .selections.form-floating>.form-select {
    height: calc(2.5rem + 2px);
    line-height: 1.25;
}

.selections.form-floating>.form-select {
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.glow-filter{
    transition: 0.5s ease;
    filter:drop-shadow(2px 2px 5px #2f363d);

}